<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions
        :column="3"
        class="simiot-descriptions simiot-descriptions-max-10"
      >
        <a-descriptions-item label="账单批次">
          {{ agentSubscribeTrafficPoolOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentSubscribeTrafficPoolOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="账单时间">
          {{ agentSubscribeTrafficPoolOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="流量池名称">
          {{ agentSubscribeTrafficPoolOrder.package_name }}
        </a-descriptions-item>

        <a-descriptions-item label="订购周期数">
          {{ agentSubscribeTrafficPoolOrder.cycles | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="账单金额(元)">
          {{ agentSubscribeTrafficPoolOrder.total_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>
  </div>
</template>

<script>
import { findAgentSubscribeTrafficPoolOrder } from '@/api/agent_subscribe_traffic_pool_order'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'ShowAgentSubscribeTrafficPoolOrder',
  data() {
    return {
      agentSubscribeTrafficPoolOrder: {},
      loading: true,
      data: []
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '客户套餐',
          width: 220,
          dataIndex: 'agents_product_name'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '订购周期数',
          dataIndex: 'cycles'
        },
        {
          title: '订购金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        }
      ]
    },

    agentSubscribeTrafficPoolOrderId() {
      return parseInt(this.$route.params.subscribe_traffic_pool_order_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentSubscribeTrafficPoolOrder(this.agentSubscribeTrafficPoolOrderId).then((res) => {
        if (res.code === 0) {
          this.agentSubscribeTrafficPoolOrder = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

